.section-home {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.div-card-home {
    width: 600px;
    background-color: white;
    padding-bottom: 20px;
    border-radius: 0 0 10px 10px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 10px 10px -5px;
    margin: 20px 0;
}

.img-banner-home {
    width: 100%;
}

.div-btn {
    display: flex;
    justify-content: flex-end;
}

.a-btn-submit {
    padding: 10px 25px;
    margin-top: 20px;
    color: white;
    border-radius: 5px;
}

.div-btn-companion {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.btn-companion {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 115px;
    height: 115px;
    text-align: center;
    border-radius: 50%;
    color: white;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 10px 10px -5px;
    cursor: pointer;
    transition: 0.3s all ease-in-out;
}

/* .btn-companion:hover {
  background-color: #8080fb;
} */

.div-checkbox {
    display: flex;
    gap: 5px;
}

@media (max-width: 600px) {
    .div-card-home {
        width: 95%;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .div-btn {
        justify-content: center;
    }

    .a-btn-submit {
        width: 100%;
        text-align: center;
    }
}