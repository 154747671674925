.section-login {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.div-card-login {
    width: 600px;
    background-color: white;
    padding-bottom: 20px;
    border-radius: 0 0 10px 10px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 10px 10px -5px;
}

.img-banner-login {
    width: 100%;
}

.div-btn {
    display: flex;
    justify-content: flex-end;
}

.a-btn-submit {
    background-color: rgb(81, 214, 81);
    padding: 10px 25px;
    margin-top: 20px;
    color: white;
    border-radius: 5px;
    transition: 0.3s all ease-in-out;
    border: none;
}

.a-btn-submit:hover {
    background-color: #28c328;
    padding: 10px 25px;
    margin-top: 20px;
    color: white;
    border-radius: 5px;
    text-decoration: none;
}

@media (max-width: 600px) {
    .div-card-login {
        width: 95%;
    }

    .div-btn {
        justify-content: center;
    }

    .a-btn-submit {
        width: 100%;
        text-align: center;
    }
}