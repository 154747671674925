@import './bootstrap.scss';

// body,
// #root,
// .card,
// .list-group,
// .list-group-item,
// table,
// td {
//   background-color: #0d6aa8 !important;
//   color: #000 !important;
// }
a:hover {
  cursor: pointer;
}

.card-columns {
  column-count: 3;
  grid-column-gap: 1.25rem;
  -webkit-column-gap: 1.25rem;
  grid-column-gap: 24px;
  column-gap: 24px;
  orphans: 1;
  widows: 1;
}

.card-form {
  width: 80%;
}

@media screen and (max-width: 600px) {
  .card-form {
    width: 100%;
  }
}

.btn {
  width: 10rem;
}

.card {
  width: 800px;
}

@media (max-width: 800px) {
  .card {
    width: 100%;
  }
}

input + img {
  width: 3.2rem;
  height: 2rem;
}

.modal-dialog {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-header {
  border: none;
}
.modal-footer {
  border: none;
}
@media (max-width: 600px) {
  .modal-content {
    display: flex;
    width: 80vw;
  }
}

.btn-gs1-login {
  height: 5rem;
}

.form-check-input {
  background-color: white;
}

input:focus,
select:focus {
  border-color: #435ce0 !important;
  box-shadow: 0 0 5px #435ce0 !important; /* Adiciona uma sombra para destacar */
}

input[type='checkbox']:checked,
input[type='radio']:checked {
  background-color: #435ce0 !important;
  border-color: #435ce0 !important;
}

// body {
//   background-image: url('');
//   background-size: cover;
//   background-position: center;
//   background-repeat: no-repeat;
//   margin: 0;
//   height: 100vh;
//   background-attachment: fixed;
// }

// .card {
//   background-color: transparent !important;
// }
.form-check-label {
  font-size: 0.6rem !important;
}
